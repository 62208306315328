<template>
  <div class="ele-body">
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-form ref="form" :model="sizeform" label-width="120px" class="d-flexspabet">
          <el-card shadow="never" class="w-40 mt-20">
            <div class="d-flex smsInner">
              <div class="blueLine">|</div>
              <div>账户余额（元）</div>
            </div>
            <div class="d-flexspabet" style="padding:20px 0 10px 30px">
              <div style="font-size:25px">
                {{amountData.balance}}
                <div style="font-size:10px;color:#ff892e">上次充值金额{{amountData.recharge_balance}}元</div>
              </div>
              <div>
                <el-button type="primary" @click="dialogVisiblePlans = true" v-if="permission.includes('sys:adminiaelist_assets:recharge')">立即充值</el-button>
              </div>
            </div>
          </el-card>
          <el-card shadow="never" class="w-40 mt-20">
            <div class="d-flex smsInner">
              <div class="blueLine">|</div>
              <div>可提现金额（元）</div>
            </div>
            <div class="d-flexspabet" style="padding:20px 0 10px 30px">
              <div style="font-size:25px">
                {{amountData.balance}}
                <div style="cursor: pointer;font-size:10px;color:#33cc99;text-decoration:underline" @click="handleAccount">我的提现账号 >
               </div>
              </div>
              <div>
                <el-button type="primary" @click=" dialogVisibleWithdrawal= true" v-if="permission.includes('sys:adminiaelist_assets:withdraw')">立即提现</el-button>
              </div>
            </div>
          </el-card>
          <el-card shadow="never" class="w-40 mt-20">
            <div class="d-flex smsInner">
              <div class="blueLine">|</div>
              <div>已冻结金额（元 ）</div>
            </div>
            <div class="d-flexspabet" style="flex-wrap: wrap;padding:20px 0 10px 30px">
             <div class="d-flex" style="flex-wrap: nowrap;align-items: center;">
               <div style="font-size:25px">
                 {{amountData.freeze_amount}}
                 <div style="font-size:10px;color:#ff892e">剩余解冻</div>
               </div>
               <div class="ml-20" style="margin-left:40px">
                 <el-button type="primary" @click="handleUnfreezing" v-if="permission.includes('sys:adminiaelist_assets:freeze')">申请解冻 </el-button>
               </div>
             </div>
              <div class="d-flex" style="flex-wrap: nowrap;display: flex;align-items: center;">
                <div style="font-size:25px">
                  {{amountData.unfreeze_amount}}
                  <div style="font-size:10px;color:#ff892e">待审核解冻</div>
                </div>
                <div style="margin-left:40px">
                  <el-button style="background:#C1C2C2;color:#fff">已申请 </el-button>
                </div>
              </div>
            </div>
          </el-card>
        </el-form>
        <el-card shadow="never" class="mt-20 " style="width:100%">
					<div><span class="blueLine">|</span>充值记录</div>
          <el-form :model="table.where" class="ele-form-search d-flex "
                   @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
            <el-form-item label="支付方式:" label-width="80px">
              <el-select v-model="table.where.type" class="input163" clearable>
                <el-option value="1" label="微信"></el-option>
                <el-option value="2" label="支付宝"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="充值金额(元):" label-width="100px">
              <el-input v-model="table.where.price" placeholder="请输入充值金额" class="input163" clearable/> 元 
            </el-form-item>
            <el-form-item label="开始时间:" label-width="80px">
              <el-date-picker v-model="table.where.start_time" type="date" placeholder="选择日期" style="width:163px" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:" label-width="80px">
              <el-date-picker v-model="table.where.end_time" type="date" placeholder="选择日期" class="mr-20" style="width:163px" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
              <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
              <el-button type="primary" @click="exportExcel" icon="el-icon-download" v-if="permission.includes('sys:assets.vue:export')">导出</el-button>
            </el-form-item>
          </el-form>
          <!-- 数据表格 -->
          <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-rows :stripe=true>
            <template slot-scope="{index}">
              <el-table-column type="index" :index="index" label="编号" width="60" align="center"  show-overflow-tooltip/>
              <el-table-column prop="name" label="管理员名称"  show-overflow-tooltip min-width="120px"/>
              <el-table-column prop="set_area" label="地区"  show-overflow-tooltip min-width="120px"/>
              <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip  min-width="120px"/>
              <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120px"/>
              <el-table-column prop="price"  label="充值金额（元 ）"  show-overflow-tooltip  min-width="120px"/>
              <el-table-column label="充值时间"  show-overflow-tooltip  min-width="120px">
                <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
              </el-table-column>
              <el-table-column prop="type_name" label="支付方式"  show-overflow-tooltip min-width="120px"/>
            </template>
          </ele-data-table>

        </el-card>
        <!--立即充值-->
        <el-dialog v-dialogDrag title="立即充值" :visible.sync="dialogVisiblePlans" custom-class="ele-dialog-form" :lock-scroll="false" @closed="editForm={}">
          <el-card shadow="never" >
            <el-form :model="editForm"   label-width="170px" class="plansBox">
              <el-form-item label="充值金额(元)：" prop="money">
                <el-input v-model="editForm.money" class="input216" placeholder="请输入充值金额" clearable/> 元
              </el-form-item>
              <el-form-item label="支付方式：" prop="pay">
                <el-button type="primary"  @click="handleWxpay">微信支付</el-button>
                <el-button style="display: none;"  @click="handleAlipay">支付宝支付</el-button>
              </el-form-item>
                 <div style="color: orangered;margin-left: 90px;">*充值金额将直接到达可蚁点平台的微信商户平台，但微信商户会收取0.6%的手续费，所以最终充值实际到账金额=充值金额-充值金额*0.6%</div>
            </el-form>
          </el-card>
        </el-dialog>
        <!--我的提现账户-->
        <el-dialog v-dialogDrag title="我的提现账户" :visible.sync="dialogVisibleSending" custom-class="ele-dialog-form" :lock-scroll="false"  @closed="editSend={}" >
          <el-card shadow="never" >
            <el-form :model="editSend"   label-width="170px" class="plansBox  ">
              <el-form-item label="银行卡账号：" >
                <el-input v-model="editSend.card"  clearable/>
              </el-form-item>
              <el-form-item label="持卡人姓名：">
                <el-input v-model="editSend.card_name"  clearable/>
              </el-form-item>
              <el-form-item label="联系电话：">
                <el-input v-model="editSend.card_phone"  clearable/>
              </el-form-item>
            </el-form>
          </el-card>   
          <div slot="footer">
            <el-button @click="dialogVisibleSending=false">取消</el-button>
            <el-button type="primary" @click="sendingSave" class="ele-btn-icon ml-20">确认</el-button>
          </div>
      
        </el-dialog>
        <!--立即充值-->
        <el-dialog v-dialogDrag title="提现金额" :visible.sync="dialogVisibleWithdrawal" custom-class="ele-dialog-form" :lock-scroll="false" @closed="editWith={}">
          <el-card shadow="never" >
            <el-form :model="editWith"   label-width="170px" class="plansBox">
              <el-form-item label="提现金额(元)：" prop="money">
                <el-input v-model="editWith.money"  clearable/>
              </el-form-item>
              <el-form-item class="d-flex f-end">
                <el-button @click="dialogVisibleWithdrawal=false">取消</el-button>
                <el-button type="primary"  class="ele-btn-icon ml-20" @click="submitMoney">提交</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-dialog>

    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import urlencode from "urlencode";
import setting from '../../../config/setting';
export default {
  name: "SysUser",
  data() {
    return {
      editWith:{},
      editSend:{},
      table: {url: '/adminiaelist/recharge_list', where: {}},  // 表格配置
      dialogVisiblePlans:false,
      dialogVisibleSending:false,
      dialogVisiblePhone:false,
      dialogVisibleWithdrawal:false,
      choose: [],  // 表格选中数据
      editForm: {},  // 表单数据
      checkForm:{},
      provArr:[],
      cityArr:[],
      districtArr:[],
      urgentList:[],
      imageUrl:false,
      headImg:'',
      activeName:'first',
      dialogVisibleAdd:false,
      dialogVisibleOpen:false,
      dialogFormVisibleView:false,
      sizeform:{},
      voiceRecharge:{},
      amountData:{},
      admin_id:'',
      amountDatalist:{}
    }
  },
  created(){
    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      this.provArr=data
    })
    const loading = this.$loading({lock: true});
    this.$http.get('/adminiaelist/recharge_list').then(res=>{
      this.amountData=res.data.amount_data
      this.amountDatalist=res.data.data
      this.admin_id=res.data.admin_id
      loading.close()
    })

    this.$http.get('/voice/voiceModel').then(res=>{
      // let data=JSON.parse(res.data)
      this.sizeform=res.data
    })
    this.$http.get('/voice/voiceRecharge').then(res=>{

      this.voiceRecharge=res.data
    })

  },

  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
	},
  methods: {
    sendingSave(){
      const loading = this.$loading({lock: true});
      this.$http.post('/adminiaelist/get_account',this.editSend).then(res => {
          loading.close();
        this.dialogVisibleSending=false
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
            } else {
              this.$message.error(res.data.msg);
            }
      

       })
    },
    //我的提现账号
    handleAccount(){
      this.dialogVisibleSending = true
      this.$http.get('/adminiaelist/get_account').then(res => {
         this.editSend=res.data
       })
    },
    //提现金额
    submitMoney(){
        const loading = this.$loading({lock: true});
        this.$http.post('/adminiaelist/admin_withdraw',{money:this.editWith.money}).then(res => {
            loading.close();
            //console.log(res)
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
    },
    // 申请解冻
    handleUnfreezing(){
         this.$confirm('确定要申请解冻吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.get('/adminiaelist/admin_thawing').then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);

    },
    handleWxpay(){
      if(this.editForm.money>=1){
          let url = "?type=1&price="+this.editForm.money+"&id="+this.admin_id;
            url = urlencode(url);
          window.open(setting.payUrl + 'index.php/SystemPay/wx_pay?'+url)
          this.dialogVisiblePlans=false
        // this.table.reload();

        this.$confirm('是否支付成功?', '提示', {
          type: 'warning',
          distinguishCancelAndClose: true,
          confirmButtonText: '是',
          cancelButtonText: '否',
        }).then(() => {
          window.location.reload()
        }).catch(e => {
          //console.log('未支付成功')
        })

        //  this.$router.push({ path: "/system/adminiaelist" });
      }else{
				this.$message({type: 'error', message: '充值金额需要大于1元'});
			}
      // if(this.editForm.money<0 || this.editForm.money<1000){
      //    this.$message({type: 'error', message: '充值金额必须大于1000'});
      //
      // }
      if(!this.editForm.money){
         this.$message({type: 'error', message: '充值金额需要大于1元'});
      }
       
    },
    handleAlipay(){
      if(this.editForm.money>=1){
        let url = "?type=2&price="+this.editForm.money+"&id="+this.admin_id;
        url = urlencode(url);
        window.open(setting.payUrl + 'index.php/SystemPay/ali_pay?'+url)
        this.dialogVisiblePlans=false
        this.table.reload();
        //  this.$router.push({ path: "/system/adminiaelist" });
      }else{
				this.$message({type: 'error', message: '充值金额需要大于1元'});
			}
      // if(this.editForm.money<0 || this.editForm.money<1000){
      //    this.$message({type: 'error', message: '充值金额必须大于1000'});
      //
      // }
      if(!this.editForm.money){
         this.$message({type: 'error', message: '充值金额需要大于1元'});
      }
    },
    form(){},
    exportExcel(){},
    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table1.where.cid=''
        this.table1.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table1.where.aid=''
      })
			}else{
				this.table1.where.pid = ''
				this.table1.where.aid = ''
			}
    },
    handlecheck(row){
      this.dialogVisiblePhone=true

    },
    /**
     *选择省
     **/
    handleChangeProv2(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table2.where.cid=''
        this.table2.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity2(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table2.where.aid=''
      })
			}else{
				this.table2.where.pid = ''
				this.table2.where.aid = ''
			}
    },
    save(){
      const loading = this.$loading({lock: true});
      let sizeform=this.sizeform
      let param={num1:sizeform.num1,price1:sizeform.price1,num2:sizeform.num2,price2:sizeform.price2,num3:sizeform.num3,price3:sizeform.price3}
      this.$http.post('/voice/voiceTempData',param).then(res=>{
        loading.close();
        let data=JSON.parse(res.data)
        if(data.code==1){
          this.$message({
            message:data.msg,
            type:'success'
          })
        }else{
          this.$message.error(data.msg)
        }
      }).catch(e=>{
        this.$message.error(e.message);

      })
    },
    handleClick(){},
    switch_start(row){
      if(row.type==1){
        this.$confirm('确定关闭语音通话吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/voice/openingType', {id:row.id,type:2}).then(res => {
            loading.close();
            let data=JSON.parse(res.data)
            if (data.code === 1) {

              this.$message({type: 'success', message:'关闭成功'});
              this.$refs.table.reload();
            } else {
              this.$message.error(data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }else{
        this.$confirm('确定启用语音通话吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});

          this.$http.post('/voice/openingType', {id:row.id,type:1}).then(res => {
            let data=JSON.parse(res.data)
            loading.close();
            if (data.code === 1) {
              this.$message({type: 'success', message:'启用成功'});
              this.$refs.table.reload();
              this.switchStart='关闭'
            } else {
              this.$message.error(data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }
    }
  }
}
</script>

<style scoped>
.lifive{width:100%;height:20px;background:#2CB394;border-top-left-radius: 6% 6%;border-bottom-right-radius: 20% 100%;
  margin-left:-10px;color:#f9f9f9;padding-top:3px;padding-left:10px;}
.ele-block .el-upload-dragger {
  width: 100%;
}
.changeStyle{
  width:150px;margin-right:30px;
}
.ele-body{
  padding:15px;
}
.el-upload-list el-upload-list--text{display:none !important}
/deep/.el-tabs__item{
  padding:0 20px !important;
  font-size: 15px;
}

/*/deep/.el-tabs__nav-scroll{*/
/*  background: white !important;*/
/*  padding-top: 10px;*/
/*}*/
.boxTitle{padding:8px}
.orderImgBox{margin-left: 10px;height: 80px;}
.orderImgBox span{
  font-size: 36px;
}
.driverForm .area /deep/.el-form-item__content{margin-left:0 !important}
.user-info-tabs >>> .el-tabs__nav-wrap {
  padding-left: 20px;
}
.w-40{width:32%;}
.innerBox{
  width:100%;
  margin-left:100px;
  padding-top: 20px;
  border-bottom: 1px rgb(240,240,240) solid;
}
.innerItem{
  width:30%;
  margin-right:100px;
}
.contentLine{line-height: 25px;margin-bottom:40px}
.smsInner{border-bottom:1px solid rgb(240,240,240);padding-bottom:10px;}
.blueLine{padding:0 10px;color:#409EFF}
.innerBoxTo{
  width:20%;
  margin-left:100px;
  padding-top: 20px;
}
.innerTitle{
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 13px;
}
.inputWidth{
  width:100%;
}
.colorOrange{color:#ff892e}
.plansBox{width:100%;border:1px solid rgb(240,240,240);margin: auto;padding:30px;box-sizing: border-box}
.plansItem{display:flex;justify-content:space-around;text-align:center;margin:0 30px;}
.plansInner{padding:0 10px;border:1px solid rgb(240,240,240);margin:20px 0;width:18%  }
.plansInner1{margin:20px 0;width:18% }
.plansCon{padding: 0 10px 10px;border:1px solid rgb(240,240,240);width:100%;margin-bottom:20px}
.plansTitle{color:#33cc99;padding:30px 0 20px 0;}
.plansMoney{color:#ff892e;padding:0 20px 20px;border-bottom:1px solid rgb(240,240,240);width:40%;margin:auto;}
.plansCluse{padding:20px;}
.plansNum{padding-bottom:40px;}
.plansMiaoshu{padding:60px 0 0;color:#33cc99}
.plansDeduct{padding-left:50px;padding-bottom:20px}
.plansDeduct span{color:#ff892e;padding-left:30px}
.plansContent{padding:30px 0;}
</style>

